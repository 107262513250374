@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/font/SFProDisplay-Bold.eot");
  src: url("./assets/font/SFProDisplay-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/font/SFProDisplay-Bold.woff2") format("woff2"),
    url("./assets/font/SFProDisplay-Bold.woff") format("woff"),
    url("./assets/font/SFProDisplay-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/font/SFProDisplay-Medium.eot");
  src: url("./assets/font/SFProDisplay-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/font/SFProDisplay-Medium.woff2") format("woff2"),
    url("./assets/font/SFProDisplay-Medium.woff") format("woff"),
    url("./assets/font/SFProDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/font/SFProDisplay-Regular.eot");
  src: url("./assets/font/SFProDisplay-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/font/SFProDisplay-Regular.woff2") format("woff2"),
    url("./assets/font/SFProDisplay-Regular.woff") format("woff"),
    url("./assets/font/SFProDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  font-family: "SF Pro Display";
  font-weight: 400;
  background-color: #000;
  color: #ffffff;
  overflow-x: hidden;
}

a {
  color: #00ffcc;
  text-decoration: none;
}

a:hover {
  color: #ff437f;
  text-decoration: underline;
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #0e0e0e;
}

img.brand_logo {
  width: 217px;
}

.navbar-brand {
  outline: 0;
}

.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #0e0e0e;
  margin-top: -100px;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
}

.navbar-sticky--on {
  margin-top: 0;
}

.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.main_navbar {
  z-index: 100;
}

.offcanvas.offcanvas-end {
  background: #101010;
}

.btn-close {
  filter: invert(1);
}

.navbar_right {
  text-align: center;
}
.navbar_right .dropdown-toggle::after {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  z-index: 0;
  height: fit-content;
}
.navbar_right .dropdown-toggle .icon {
  z-index: 1;
}

.modal-backdrop {
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.4);
}
.navbar_right {
  display: flex;
}
.address_btn {
  width: 250px;
  display: block;
  color: #fff !important ;
  text-decoration: none !important;
}
.address_btn .text1 {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.navbar_right .dropdown-menu {
  padding: 0 !important;
}
.navbar_right .dropdown-item {
  color: #fff;
  padding: 10px;
}
.navbar_right .dropdown-item:hover {
  background: #0000009c;
  text-decoration: none;
}
/* Connect Wallet */
.primary_modal .modal-content {
  background: linear-gradient(132deg, #030307, #262626);
  border: 2px solid #2a2a2a;
}

.primary_modal .modal-header {
  border-bottom: 0px solid #2a2a2a;
  padding: 20px 24px 15px;
}

.connect_wallet_button {
  border: 2px solid #2a2a2a;
  background-color: transparent;
  color: #ffffff;
  border-radius: 23px;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  transition: 0.5s;
}

.connect_wallet_button:hover {
  border-color: #565353;
}

.primary_modal .modal-header h5 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #ffffff;
}

.connect_wallet_button img {
  margin-right: 15px;
}

.connect_wallet_button span {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
}

.btn-close {
  width: 32px;
  height: 32px;
  color: #fff;
  border: 0;
  /* background: transparent url("../src/assets/images/close_icon.png") no-repeat; */
  opacity: 0.8;
  outline: none;
  box-shadow: none !important;
}

.btn-close:hover {
  opacity: 1;
}

.primary_modal a {
  text-decoration: none;
}

.h2tag {
  background: linear-gradient(
    52deg,
    #fff7a1 -54.33%,
    #e4a837 -7.67%,
    #fdf294 45.44%,
    #e4a837 80.84%,
    #fff7a1 106.59%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  width: fit-content;
}

.para {
  color: #a0a0a0;
}

.primary_btn {
  border-radius: 50px;
  background: linear-gradient(270deg, #000 0%, #111 44.95%, #7d5a00 100%)
      padding-box,
    linear-gradient(0deg, #11111100 0%, #635849 127.95%, #605a4a 100%)
      border-box;
  border: 1px solid transparent;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  padding: 11px 20px;
  box-shadow: none !important;
  padding-left: 55px;
  padding-right: 20px;
  position: relative;
  overflow: hidden;
}

.primary_btn .icon {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  transition: 1s;
}

.text1 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  right: 95%;
  gap: 10px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background: linear-gradient(270deg, #000 0%, #111 44.95%, #7d5a00 100%)
      padding-box,
    linear-gradient(0deg, #11111100 0%, #635849 127.95%, #605a4a 100%)
      border-box;
  /* border: 1px solid transparent; */
  transition: 1s;
  white-space: nowrap;
  width: 100%;
}

.primary_btn:hover .icon {
  left: calc(100% - 43px);
}

.primary_btn:hover .text1 {
  right: 0;
  padding-left: 20px;
  width: 100%;
}

.page_header {
  position: relative;
  z-index: 1;
}

.page_header::before {
  content: "";
  background: url(./assets/images/banbg.png) no-repeat center;
  background-size: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: -110px;
  bottom: 0;
  margin: auto;
  z-index: 0;
}

.page_header::after {
  content: "";
  background: url("./assets/images/banshade.png") no-repeat center;
  background-size: 100%;
  width: 100%;
  height: 845px;
  position: absolute;
  left: -0px;
  right: 0;
  top: -180px;
  bottom: 0;
  margin: auto;
  z-index: -1;
  filter: blur(10px);
}

.page_header .img_box {
  position: relative;
  z-index: -1;
  margin-top: 100px;
}

/* .page_header .img_box::before {
  content: "";
  position: absolute;
  background: radial-gradient(70.39% 37.91% at 64.65% 48.97%, #271c00 0%, #000 120%);
  width: 1000px;
  height: 1000px;
  border-radius: 50%;
  right: -100px;
  top: -180px;
  z-index: -1;
  filter: blur(21px);
} */
.page_header h1 {
  color: #fff;
  font-family: SF Pro Display;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px;
  /* 130% */
  margin-bottom: 30px;
}

.page_header h1 span {
  background: linear-gradient(
    90deg,
    #fff7a1 0.84%,
    #e4a837 25.67%,
    #fdf294 53.93%,
    #e4a837 72.77%,
    #fff7a1 86.47%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: SF Pro Display;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px;
}

.page_header .para {
  margin-bottom: 30px;
}

.page_header .para span {
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
}

.page_header .col-lg-5 {
  z-index: 5;
}

.main_wrapper {
  z-index: 3;
  position: relative;
}

.total .tot_box {
  border-radius: 15px;
  border: 1px solid #fff7a1;
  background: linear-gradient(
    180deg,
    rgba(0, 2, 3, 0.1) 0%,
    rgba(22, 24, 25, 0.1) 100%
  );
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.9),
    0px 20px 25px -5px rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(12.5px);
  padding: 55px 150px;
  position: relative;
}

.total .tot_box::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -8px;
  width: 100%;
  height: 15px;
  border-radius: 627px;
  background: radial-gradient(
    11.29% 41.47% at 48.09% 51.85%,
    #fdf294 30%,
    #e4a837 30.73%,
    rgba(0, 0, 0, 0) 150%
  );
  filter: blur(2.5px);
}

.total .tot_box h3 {
  color: #fff;
  font-size: 48px;
  font-style: normal;
  margin-bottom: 0;
  font-weight: 700;
  line-height: normal;
}

.total .tot_box p {
  font-size: 20px;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0;
  color: #a0a0a0;
}

.total .box {
  display: flex;
}

.total .img_box {
  min-width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 17, 0.25) 0%,
    rgba(160, 160, 160, 0.25) 100%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.total .right_box {
  padding-left: 60px;
}

.g_bg {
  background: url("./assets/images/g_bg.png") no-repeat top center;
  background-size: 100% 99%;
}

.features {
  padding: 100px 0 45px;
}

.features .col-lg-4:nth-child(1) .box {
  padding-right: 40px;
}

.features .col-lg-4:nth-child(2) .box {
  padding: 0 40px;
  position: relative;
}

.features .col-lg-4:nth-child(2) .box::before {
  content: "";
  position: absolute;
  background: #1b1b1b;
  left: -9px;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 1px;
  margin: auto 0;
}

.features .col-lg-4:nth-child(2) .box::after {
  content: "";
  position: absolute;
  background: #323232;
  width: 5px;
  height: 28px;
  margin: auto 0;
  left: -11px;
  top: 0;
  bottom: 0;
  border-radius: 10px;
}

.features .col-lg-4:nth-child(3) .box {
  padding-left: 40px;
}

.features .col-lg-4:nth-child(3) .box::before {
  content: "";
  position: absolute;
  background: #1b1b1b;
  left: 0px;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 1px;
  margin: auto 0;
}

.features .col-lg-4:nth-child(3) .box::after {
  content: "";
  position: absolute;
  background: #323232;
  width: 5px;
  height: 28px;
  margin: auto 0;
  left: -2px;
  top: 0;
  bottom: 0;
  border-radius: 10px;
}

.features .box h6 {
  font-size: 20px;
  font-weight: 500;
  color: #a0a0a0;
  margin-bottom: 20px;
}

.features .box .img_box {
  border-radius: 10px;
  min-width: 89px;
  width: fit-content;
  height: 89px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05) url("./assets/images/img_bg.png")
    no-repeat center;
  margin-bottom: 40px;
  border: 1px solid transparent;
}

.features .box:hover .img_box {
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: url("./assets/images/img_bg.png") no-repeat center,
    linear-gradient(
      180deg,
      rgba(255, 247, 161, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    );
  box-shadow: 0px 0px 5px 0px #fff7a1 inset;
  transition: 0.25s;
}

.features .box:hover .para,
.features .box:hover h6 {
  color: #fff;
  transition: 0.5s;
}

.platform_activity {
  margin: 0 auto;
  padding: 100px 0;
  background: url(./assets/images/platform_bg.png) no-repeat center;
  background-size: 100% 120%;
}

.platform_activity p {
  margin-bottom: 0;
}

.platform_activity p.green span {
  color: #feffff;
}

.platform_activity .activity_box_flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.03);
  background-color: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(5px);
  overflow: hidden;
}

.platform_activity .activity_box_flx:nth-child(1) {
  border-radius: 10px 10px 0px 0px;
}

.platform_activity .activity_box_flx:nth-last-child(1) {
  border-radius: 0px 0px 10px 10px;
}

.platform_activity .activity_box_flx:hover {
  border: 1px solid rgba(255, 255, 255, 0.04);
  transition: 0.5s;
}

.platform_activity .activity_box_flx::before {
  content: "";
  background: linear-gradient(
    0deg,
    rgba(253, 242, 148, 0.5) -124%,
    rgba(0, 0, 0, 0.5) 100%
  );
  transition: 0.5s;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  transition: 0.5s;
  z-index: -1;
  opacity: 0;
}

.platform_activity .activity_box_flx:hover:before {
  opacity: 1;
}

.platform_activity .activity_box_flx:nth-last-child(1) {
  margin-bottom: 20px;
}

.platform_activity .flxbox {
  display: flex;
  align-items: center;
}

.platform_activity .flxbox div {
  margin-right: 30px;
}

.platform_activity .flxbox .btn {
  background: #caf99b;
  color: #130042;
  font-size: 16px;
  font-weight: 600;
  border-radius: 30px;
  margin-right: 0;
  border: 0;
  padding: 7px 25px;
}

.platform_activity .rightbtn p {
  display: flex;
  align-items: center;
  color: #869cff;
}

.platform_activity .rightbtn p:hover {
  filter: invert(0) brightness(1.5);
}

.platform_activity .rightbtn p span:nth-child(2) {
  margin-bottom: -5px;
  color: #a0a0a0;
}

.platform_activity .activity_box .seemorebutn {
  font-size: 18px;
  color: #fff;
  background: transparent;
  border: 0;
  border-radius: 10px;
  display: block;
  margin: 0 auto;
}

.platform_activity .activity_box .seemorebutn .arrowhvr {
  display: none;
}

.platform_activity .activity_box .seemorebutn:hover .arrow {
  display: none;
}

.platform_activity .activity_box .seemorebutn:hover .arrowhvr {
  display: inline-block;
}

.faq .accordion {
  max-width: 85%;
  margin: 0 auto;
}

.faq .accordion-item {
  box-shadow: none !important;
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.03);
  background: transparent;
  border-radius: 0;
  padding: 20px 0;
  position: relative;
}

.faq .accordion-item:nth-last-child(1) {
  border: 0;
}

.faq .accordion-item button {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  box-shadow: none !important;
  padding: 0;
}

.faq .para {
  color: #fff;
  margin-bottom: 0;
}

.faq .accordion-body {
  padding: 15px 0;
}

.faq .accordion-button:not(.collapsed)::after,
.faq .accordion-button.collapsed::after {
  /* background: url("./assets/images/down_arw.png") no-repeat center; */
  width: 10px;
  height: 7px;
  transition: 0.5s;
  background: url("./assets/images/down_arw.png") no-repeat center;
}

.rewards {
  padding-top: 50px;
}

.rewards .h2tag {
  margin-bottom: 50px;
}

.rewards .box {
  border-radius: 15px;
  border: 1px solid #fff7a1;
  background: linear-gradient(180deg, #000 37.15%, #fdf294 175.45%);
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.9),
    0px 20px 25px -5px rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(11px);
  padding: 50px;
  overflow: hidden;
}

.rewards .box::before {
  content: "";
  position: absolute;
  background: url("./assets/images/reward_bg.png") no-repeat center;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-position: 100% 50%;
  z-index: -1;
}

.rewards .tab-container {
  align-items: flex-start;
  display: flex;
  gap: 24px;
  margin-bottom: 50px;
}

.rewards .tab-container .tab {
  background: linear-gradient(180deg, rgb(0, 2, 3) 0%, rgb(22, 23.74, 25) 100%);
  border-radius: 10px;
  box-shadow: inset 0px 1px 2px #525154;
  padding: 14px 20px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.rewards .tab-container .active.tab {
  background: linear-gradient(
    180deg,
    rgb(255, 247, 161) 0%,
    rgb(228, 168, 55) 29%,
    rgb(253, 242, 148) 62%,
    rgb(228, 168, 55) 84%,
    rgb(255, 247, 161) 100%
  );
  border-radius: 10px;
  color: #000;
  font-weight: 700;
}

.rewards .tab-content .tabs {
  transition: 1s;
}

.rewards .tab-content .hide {
  display: none;
}

.rewards .tab-content .show {
  display: block;
  word-break: break-word;
}

.rewards .tab-content ul {
  list-style-type: none;
  margin: 0;
  color: #fff;
  font-size: 20px;
}

.rewards .tab-content ul span {
  font-weight: 500;
}

.rewards .tab-content li {
  position: relative;
}

.rewards .tab-content li:not(:last-child) {
  margin-bottom: 20px;
}

.rewards .tab-content li::before {
  content: "";
  background: url("./assets/images/li_icon.png") no-repeat center;
  position: absolute;
  width: 34px;
  height: 33px;
  left: -38px;
}

.footer {
  background: #050505;
  padding: 20px 0;
  margin-top: 90px;
}

.footer .para {
  background: linear-gradient(
    47deg,
    #fff7a1 8.3%,
    #e4a837 32.68%,
    #fdf294 60.44%,
    #e4a837 78.94%,
    #fff7a1 92.39%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0;
  word-break: break-word;
}

.footer p {
  margin-bottom: 0;
}

.footer_social_links {
  margin-left: auto;
  list-style-type: none;
  width: fit-content;
}

.footer_social_links img {
  border-radius: 50%;
}

.joinnow {
  min-height: 100vh;
  padding-top: 90px;
  position: relative;
}

.joinnow::after {
  content: "";
  background: url("./assets/images/banshade.png") no-repeat center;
  background-size: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  left: -0px;
  right: 0;
  top: -180px;
  bottom: 0;
  margin: auto;
  z-index: -1;
  filter: blur(100px);
}

.joinnow .box {
  transition: 0.25s;
  padding: 50px 30px;
  border-radius: 15px;
  position: relative;
  border: 1px solid #fff7a1;
  background: #060606;
}

.joinnow .box::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -8px;
  width: 100%;
  height: 15px;
  border-radius: 627px;
  background: radial-gradient(
    11.29% 41.47% at 48.09% 51.85%,
    #fdf294 30%,
    #e4a837 30.73%,
    rgba(0, 0, 0, 0) 150%
  );
  filter: blur(2.5px);
}

.joinnow .box input {
  background: #040404;
  border: 1px solid #29241f;
  padding: 8px;
  box-shadow: none !important;
  color: #fff;
}

.joinnow .box .inbox {
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.03);
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.h_flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.h_flx h3 {
  font-size: 32px;
  font-weight: 700;
}

.h_flx .time_box {
  border-radius: 10px;
  background: #050505;
  display: flex;
  padding: 15px 20px;
  align-items: center;
  gap: 15px;
}
.h_flx .time_box img {
  height: 19px;
  width: auto;
}

.h_flx p {
  margin-bottom: 0;
}

.h_flx p span {
  color: #a0a0a0;
}

.dashboard {
  min-height: 100vh;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
}

.dashboard .box {
  border-radius: 40px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: rgba(255, 255, 255, 0.03);
  padding: 20px;
}

.dashboard .inbox {
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.03);
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.dashboard .inbox .img_box {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.05);
  min-width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
}

.dashboard .inbox:hover {
  transition: 0.5s;
  background: var(--new, linear-gradient(180deg, #000 0%, #181818 100%));
  box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.5),
    0px 1px 2px 0px rgba(82, 81, 84, 0.5) inset;
}

.dashboard .inbox:hover .img_box {
  transition: 0.25s;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: var(
    --Radial,
    radial-gradient(
      105.48% 67.06% at 50.59% 110%,
      #e4a837 0%,
      rgba(0, 0, 0, 0.05) 100%
    )
  );
  box-shadow: 0px 0px 5px 0px #e4a837 inset;
}

.dashboard .inbox h5 {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
}

.dashboard .inbox:hover h5 {
  background: var(
    --Gold,
    linear-gradient(
      52deg,
      #fff7a1 -54.33%,
      #e4a837 -7.67%,
      #fdf294 45.44%,
      #e4a837 80.84%,
      #fff7a1 106.59%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dashboard .inbox p {
  color: #d2d2d2;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
}

.dashboard .affiliate {
  background: var(--new, linear-gradient(180deg, #000 0%, #181818 100%));
  box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.5),
    0px 1px 2px 0px rgba(82, 81, 84, 0.5) inset;
}

.dashboard .affiliate ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard .affiliate ul a {
  background: #414141;
  color: #131313 !important;
  text-decoration: none !important;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.4s;
}

.dashboard .affiliate ul a:hover {
  background: radial-gradient(
    147.75% 93.68% at 96.67% 32.94%,
    #e4a837 0%,
    #fff 100%
  );
  box-shadow: 0px 0px 5px 0px #e4a837 inset;
}

.dashboard .affiliate .link {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.05);
  line-height: normal;
}

.dashboard .affiliate .link .half {
  width: 83%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}

.dashboard .affiliate .link .copy {
  cursor: pointer;
}

.dashboard .accordion-item {
  border-radius: 40px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: rgba(255, 255, 255, 0.03);
  padding: 0px;
  overflow: hidden;
}

.dashboard .accordion-button {
  box-shadow: none;
  background: transparent;
  color: #fff7a1;
  font-size: 22px;
  font-weight: 500;
  padding: 20px;
}

.dashboard .accordion-button::after {
  background: none;
  width: 0;
  height: 0;
  display: inline-block;
  content: "";
  border-top: 0.3em solid #a0a0a0;
  border-right: 5px solid transparent;
  border-bottom: 0;
  border-left: 5px solid transparent;
}

.dashboard .accordion-body {
  padding: 20px;
  padding-top: 0;
}

.dashboard .pack_box {
  border: 1px solid transparent;
  margin-top: 20px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.03);
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.dashboard .pack_box .img_box {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.05);
  min-width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
}

.dashboard .pack_box h5 {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
}

.dashboard .pack_box.joined {
  border-radius: 20px;
  border: 1px solid #fdf294;
  background: var(--new, linear-gradient(180deg, #000 0%, #181818 100%));
  box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.5),
    0px 1px 2px 0px rgba(82, 81, 84, 0.5) inset;
  position: relative;
  overflow: hidden;
}
.dashboard .pack_box.joined::before {
  content: "Active";
  background: radial-gradient(
    147.75% 93.68% at 96.67% 32.94%,
    #e4a837 0%,
    #fff 100%
  );
  box-shadow: 0px 0px 5px 0px #e4a837 inset;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  transform: rotate(45deg);
  position: absolute;
  top: 10px;
  right: -30px;
  text-align: center;
  width: 127px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard .pack_box.joined .img_box {
  transition: 0.25s;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: var(
    --Radial,
    radial-gradient(
      105.48% 67.06% at 50.59% 110%,
      #e4a837 0%,
      rgba(0, 0, 0, 0.05) 100%
    )
  );
  box-shadow: 0px 0px 5px 0px #e4a837 inset;
}



.dashboard .pack_box.disabled {
  border-radius: 20px;
  border: 1px solid #fdf294;
  background: var(--new, linear-gradient(180deg, #000 0%, #181818 100%));
  box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.5),
    0px 1px 2px 0px rgba(82, 81, 84, 0.5) inset;
  position: relative;
  overflow: hidden;
}
.dashboard .pack_box.disabled::before {
  content: "Inactive";
  background: radial-gradient(
    147.75% 93.68% at 96.67% 32.94%,
    #e4a837 0%,
    #fff 100%
  );
  box-shadow: 0px 0px 5px 0px #e4a837 inset;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  transform: rotate(45deg);
  position: absolute;
  top: 10px;
  right: -30px;
  text-align: center;
  width: 127px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard .pack_box.disabled .img_box {
  transition: 0.25s;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: var(
    --Radial,
    radial-gradient(
      105.48% 67.06% at 50.59% 110%,
      #e4a837 0%,
      rgba(0, 0, 0, 0.05) 100%
    )
  );
  box-shadow: 0px 0px 5px 0px #e4a837 inset;
}


.dashboard .pack_box.disabled h5 {
  color: #333;
}

.dashboard .pack_box.disabled .img_box {
  opacity: 0.35;
}
.dashboard .prg-flex {
  display: flex;
  align-items: center;
}
.dashboard .join_btn {
  border-radius: 10px;
  background: radial-gradient(
    147.75% 93.68% at 96.67% 32.94%,
    #e4a837 0%,
    #fff 100%
  );
  box-shadow: 0px 0px 5px 0px #e4a837 inset !important;
  padding: 5px 10px;
  border: 0;
  font-size: 14px;
  font-weight: 500;
  outline: 0;
}

.dashboard thead tr {
  border-radius: 20px 20px 0 0 !important;
  background: var(--new, linear-gradient(180deg, #000 0%, #181818 100%));
  box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.5),
    0px 1px 2px 0px rgba(82, 81, 84, 0.5) inset;
}

.dashboard thead th {
  border: 0;
}

.dashboard tbody tr {
  border: 1px solid rgba(255, 255, 255, 0.03);
  background: rgba(255, 255, 255, 0.03);
  transition: 0.5s;
}

.dashboard tbody tr:hover {
  border: 1px solid rgba(255, 255, 255, 0.04);
  background: linear-gradient(
    0deg,
    rgba(253, 242, 148, 0.5) -124%,
    rgba(0, 0, 0, 0.5) 100%
  );
  backdrop-filter: blur(5px);
}

.dashboard thead th:nth-child(1) {
  border-radius: 20px 0px 0 0 !important;
}

.dashboard thead th:nth-last-child(1) {
  border-radius: 0px 20px 0 0 !important;
}

.dashboard th {
  color: #d2d2d2;
  font-size: 20px;
  font-weight: 700;
}

.dashboard td,
.dashboard th {
  background-color: transparent;
  color: #d2d2d2;
  padding: 20px 5px;
  white-space: nowrap;
}

.dashboard td:nth-child(1),
.dashboard th:nth-child(1) {
  padding-left: 20px;
}

.dashboard td:nth-last-child(1),
.dashboard th:nth-last-child(1) {
  padding-right: 20px;
}

.dashboard .progress-bar {
  border-radius: 10px;
  background: radial-gradient(
    147.75% 93.68% at 96.67% 32.94%,
    #e4a837 0%,
    #fff 100%
  );
  box-shadow: 0px 0px 5px 0px #e4a837 inset;
}

.dashboard .progress {
  border-radius: 20px;
  background: #313030;
  height: 12px;
}

/*Now the CSS*/
/* .tree{
    width: 11000px;
} */
.tree {
  margin: 0 auto;
  width: fit-content;
}
.tree ul {
  padding-top: 20px;
  position: relative;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;

  display: flex;
  padding-left: 0;
}

.tree li {
  float: left;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0 5px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

/*We will use ::before and ::after to draw the connectors*/

.tree li::before,
.tree li::after {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 2px solid #222;
  width: 50%;
  height: 20px;
  border-radius: 0 !important;
}

.tree li::after {
  right: auto;
  left: 50%;
  border-left: 2px solid #222;
}

/*We need to remove left-right connectors from elements without 
any siblings*/
/* .tree li:only-child::after,
.tree li:only-child::before {
  display: none;
} */

/*Remove space from the top of single children*/
/* .tree li:only-child {
  padding-top: 0;
} */

/*Remove left connector from first child and 
right connector from last child*/
.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}

/*Adding back the vertical connector to the last nodes*/
.tree li:last-child::before {
  border-right: 2px solid #222;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}

.tree li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}

/*Time to add downward connectors from parents*/
.tree ul ul::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 2px solid #222;
  width: 0;
  height: 20px;
}

.tree li a {
  /* border: 2px solid #222;
	padding: 5px 10px; */
  text-decoration: none;
  display: inline-block;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;

  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.tree li a span {
  color: #fff;
}
/*Time for some hover effects*/
/*We will apply the hover effect the the lineage of the element also*/
/* .tree li a:hover, .tree li a:hover+ul li a {
	background: #c8e4f8; color: #000; border: 1px solid #94a0b4;
} */

/*Connector styles on hover*/
/* .tree li a:hover+ul li::after, 
.tree li a:hover+ul li::before, 
.tree li a:hover+ul::before, 
.tree li a:hover+ul ul::before{
	border-color:  #94a0b4;
} */
.float-right{
  float: right;
}
.tree li:only-child::before {
  content: "";
  padding-top: 0;
  border: 0;
  margin: 0 auto;
  left: 2px;
  right: 0;
  width: 2px;
  height: 20px;
  background: #222;
}
.usr_rose {
  min-width: 31px;
  width: 31px;
}
.usr_green {
  min-width: 31px;
  width: 31px;
}
.usr_vio {
  min-width: 25px;
}
.current_user::before {
  display: none;
}
.dwn_btn {
  width: 100%;
  display: block;
  text-align: center;
}
.dwn_btn:hover {
  color: #fff;
  text-decoration: none;
}

.pagination {
  /* justify-content: flex-end; */
  margin-right: 8px;
  margin-bottom: 30px;
  margin-left: 12px;
}
.page-item .page-link {
  background-color: #101010;
  border-color: #1b1b1b00;
  box-shadow: none;
  color: #fff;
}
.page-link:hover {
  text-decoration: none;
  color: #fff7a1;
}
.active > .page-link,
.page-link.active {
  color: #fff7a1;
  background-color: #101010;
}
.page-item.disabled .page-link {
  color: #423d3d;
}

.wid {
  width: fit-content;
  margin: 0 auto;
  border-radius: 50px;
}

.react-tooltip {
  z-index: 10;
}

@media (max-width: 1399px) {
  .dashboard .pack_box.joined::before {
    right: -40px;
  }
}

@media (max-width: 1199px) {
  .total .tot_box {
    padding: 55px;
  }

  .rewards .tab-container {
    gap: 12px;
  }

  .rewards .tab-container .tab {
    padding: 10px 15px;
  }
}

@media (max-width: 991px) {
  ul.pagination {
    overflow-x: scroll;
  }
  .navbar_right {
    flex-direction: column;
  }
  .navbar_right .primary_btn {
    margin: 0 auto 0px !important;
    text-align: left;
    width: fit-content;
    padding-right: 30px;
  }
  .dropdown {
    margin: 0 auto 10px !important;
  }
  .page_header {
    padding-top: 70px;
  }
  .dashboard .affiliate ul {
    gap: 20px;
    justify-content: center;
  }
  .h_flx {
    flex-direction: column;
  }
  .total .right_box {
    padding-left: 0;
    padding-top: 15px;
  }

  .total .tot_box h3 {
    font-size: 40px;
  }

  .features .col-lg-4:nth-child(2) .box::before,
  .features .col-lg-4:nth-child(2) .box::after,
  .features .col-lg-4:nth-child(3) .box::before,
  .features .col-lg-4:nth-child(3) .box::after {
    display: none;
  }

  .features .col-lg-4:nth-child(2) .box {
    padding: 20px 0;
  }

  .features .col-lg-4:nth-child(3) .box {
    padding-left: 0;
  }

  .rewards .tab-container {
    flex-wrap: wrap;
  }

  .rewards .tab-content ul {
    font-size: 18px;
  }

  .g_bg {
    background-size: unset;
  }
}

@media (max-width: 767px) {
  .h2tag {
    font-size: 30px;
    line-height: unset;
  }
  .dashboard .prg-flex {
    width: 200px;
  }
  .dashboard .pack_box h5 {
    font-size: 21px;
  }
  .platform_activity .activity_box {
    padding: 17px;
  }
  .coin_wrapper {
    transform: scale(0.8);
    margin-top: -50px !important;
  }

  .platform_activity .flxbox {
    flex-wrap: wrap;
  }

  .platform_activity .flxbox div {
    margin: 5px;
    margin-right: 15px;
    margin-left: 0;
  }

  .platform_activity .activity_box_flx {
    flex-direction: column;
    align-items: baseline;
    padding: 20px;
  }

  .platform_activity .rightbtn p {
    flex-wrap: nowrap;
    white-space: nowrap;
    margin-top: 17px;
  }

  .rewards .box,
  .total .tot_box {
    padding: 25px;
  }

  .footer_social_links {
    margin-left: 0;
    padding: 0;
    padding-top: 15px;
  }

  .total .img_box {
    min-width: 70px;
    height: 70px;
  }

  .total .img_box img {
    width: 45px;
  }

  .total .tot_box h3,
  .page_header h1 {
    font-size: 32px;
    line-height: unset;
  }
}

@media (max-width: 500px) {
  .coin_wrapper {
    transform: scale(0.5);
    margin-top: -60px !important;
  }
}
@media (max-width: 400px) {
  .coin_wrapper {
    transform: scale(0.5);
    margin-top: -90px !important;
  }
}

.coin_wrapper {
  position: relative;
  z-index: 10;
  font-size: 277px;
  width: 0.1em;
  height: 1em;
  background: linear-gradient(#000, #000);
  margin: auto;
  box-shadow: 0px 0px 50px #ffc861;

  position: absolute;
  left: 0;
  right: 0;
}

.coin {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-animation: rotate 3s infinite linear;
  animation: rotate 3s infinite linear;
  transform-style: preserve-3d;
  z-index: -1;
}

.coin .side,
.coin:before,
.coin:after {
  content: "";
  position: absolute;
  width: 1em;
  height: 1em;
  overflow: hidden;
  background: #000;
  border-radius: 50%;
  right: -120px;
  right: -0.396em;
  text-align: center;
  line-height: 1;
  color: #000;
  text-shadow: 1px 2px 0 #000, -1px -2px 0 #000;
  transform: rotateY(-90deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.coin .tails,
.coin:after {
  left: -120px;
  left: -0.396em;
  transform: rotateY(90deg);
}

.coin:before,
.coin:after {
  background: linear-gradient(#000, #000);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateY(90deg);
}

.coin:after {
  transform: rotateY(-90deg);
}

.imageCoin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  min-height: 1em;
  background-color: #000;
}

@-webkit-keyframes rotate {
  100% {
    transform: rotateY(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotateY(360deg);
  }
}

.dropdown-menu.show {
  display: block;
  background: #030303;
  color: #fff;
  font-size: 15px;
  padding-left: 16px;
  padding-top: 20px;
  padding: 14px;
}

.dropdown .dropdown-menu p {
  font-size: 16px;
  font-weight: 400;
  color: #d6dadf;
}

.tabledeatils {
  margin-top: 30px;
}

.tabledeatils h6 {
  font-size: 20px;
  font-weight: 600;
  color: #f8dc52;
  margin-bottom: 10px;
}

.tabledeatils .rdt_TableHeadRow {
  background: #151515 !important;
  color: #d1d1d1;
  font-weight: 600;
  font-size: 18px;
}

.tabledeatils .rdt_TableRow {
  background-color: #0d0d0d;
  color: #fff;
}

.tabledeatils .rdt_Pagination {
  background: #0f0f0f;
  color: #fff;
}

.tabledeatils .rdt_Pagination button {
  fill: rgb(255 255 255 / 54%);
}

.tabledeatils .rdt_Pagination button:disabled {
  opacity: 0.5;
}

.tabledeatils .rdt_Pagination select {
  background: #0f0f0f;
}

.tabledeatils .rdt_Pagination option {
  background: #0f0f0f;
  color: #fff;
}

.tabledeatils .rdt_Pagination option[selected] {
  background: #0f0f0f !important;
}

.loadBefore::before {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgb(10 10 10 / 75%);
  z-index: 1500;
  overflow: hidden;
}

/* #main {
  display: block;
  width: 277px; 
 height: 277px;
  top: 10px;
  left: 10px;
  -webkit-transform-style: preserve-3d;
  -webkit-perspective: 1000px;
 animation:turn 2.5s infinite linear;
 margin:auto;
 top:12%;
 position: absolute; 
    left: 0;
    right: 0;
}

.coin {
  background: url("./assets/images/ban_pic.png");
  border-radius: 50%;
  position: relative;
  -webkit-transform-style: preserve-3d;
  animation: spin 3s linear infinite;
  width: 277px;
  height: 277px;
  transform: rotateY(154deg); 
  
  box-shadow: 0px 0px 50px #FFC861;
}
@keyframes spin {
  0% {
    transform: rotateY(0deg);
 }
  100% {
    transform: rotateY(360deg);
 }
}
.coin:before {
  background-color: black;
  position: absolute;
  border-radius: 50%;
  content: "";
  -webkit-transform: translateZ(-0.75em);
  width: 277px;
  height: 277px;
}
.coin:after {
  background-color: black;
  content: "";
  left: 50%;
  position: absolute;
  height: 276px;
  width: 0.7em;
  z-index: -10;
  -webkit-transform: rotateY(-90deg);
  -webkit-transform-origin: 100% 50%;
  top: 1px;
}
.tails {
  background-color: black;
  position: absolute;
  border-radius: 50%;
  content: "";
  -webkit-transform: translateZ(-0.005em);
  border: 1px solid #FF9900;
  width: 277px;
  height: 277px;
}
.heads {
  position: absolute;
  border-radius: 50%;
  content: "";
  -webkit-transform: translateZ(-0.755em);
  border: 1px solid #FF9900;
  background: url("./assets/images/ban_pic.png");
  width: 277px;
  height: 277px;
  transform: translateZ(-12.4px) rotateY(180deg);
} */

.plan_pool {
  width: 3000px;
  margin-top: 40px;
}

.plan_pool img {
  z-index: 1;
  outline: 0;
}

.plan_pool_head {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 56px;
  position: relative;
  margin: 10px auto 40px;
}

.plan_pool_head::after {
  content: "";
  background-color: #533ba1;
  width: 1px;
  height: 57%;
  position: absolute;
  bottom: -40px;
  left: -2px;
  right: 0;
  margin: 0 auto;
}

.plan_pool_head_child {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  position: relative;
  margin: 29px 5px 0;
}

.plan_pool_first_row .plan_pool_head_child {
  width: 100px;
  height: 40px;
  font-size: 36px;
}

.plan_pool_first_row {
  display: flex;
  justify-content: space-around;
  position: relative;
}

.plan_pool_first_row::after {
  content: "";
  background-color: #533ba1;
  width: 66.7%;
  height: 1px;
  position: absolute;
  top: 1px;
}

.plan_pool_head_child::before {
  content: "";
  width: 1px;
  height: 78%;
  position: absolute;
  top: -27px;
  background: #533ba1;
}

.plan_pool_second_row .plan_pool_head_child {
  width: 32px;
  height: 45px;
  font-size: 30px;
  border-radius: 12px;
  margin-top: 26px;
}

.plan_pool_second_row > div {
  display: flex;
  justify-content: space-around;
  position: relative;
  margin-top: 30px;
  width: 32.7%;
}

.plan_pool_second_row {
  display: flex;
  justify-content: space-around;
}

.plan_pool_first_row .plan_pool_head_child::after {
  content: "";
  background-color: #533ba1;
  width: 1px;
  height: 60%;
  position: absolute;
  bottom: -32px;
}

.plan_pool_second_row > div::before {
  content: "";
  background-color: #533ba1;
  width: 67%;
  height: 1px;
  position: absolute;
  top: -1px;
}

.plan_pool_second_row .plan_pool_head_child::after {
  content: "";
  background-color: #533ba1;
  width: 1px;
  height: 60%;
  position: absolute;
  bottom: -32px;
}

.plan_pool_second_row img {
  min-width: 45px;
  height: 45px;
}

.plan_pool_third_row img {
  min-width: unset;
  height: unset;
}

.plan_pool_third_row {
  display: flex;
  justify-content: space-around;
}

.plan_pool_third_row .plan_pool_head_child::after {
  width: 0px;
}

.plan_pool_third_row > div::before {
  content: "";
  background-color: #533ba1;
  width: 67%;
  height: 1px;
  position: absolute;
}
.plan_pool_third_row > div {
  display: flex;
  justify-content: space-around;
  position: relative;
  margin-top: 30px;
  width: 32.7%;
}

.plan_pool_third_row > div > div {
  display: flex;
}

.plan_pool_fourth_row img {
  min-width: unset;
  height: unset;
}

.plan_pool_fourth_row {
  display: flex;
  justify-content: space-around;
}

.plan_pool_fourth_row .plan_pool_head_child::after {
  width: 0px;
}
.plan_pool_fourth_row > div {
  position: relative;
}
.plan_pool_fourth_row > div::before {
  content: "";
  background-color: #533ba1;
  width: 67%;
  height: 1px;
  position: absolute;
}
.plan_pool_fourth_row > div::after {
  content: "";
  background-color: #533ba1;
  width: 1px;
  height: 50px;
  position: absolute;
  left: 50%;
  top: -50px;
  margin-left: -1px;
}
.plan_pool_fourth_row > div {
  display: flex;
  justify-content: space-around;
  position: relative;
  margin-top: 30px;
  width: 32.7%;
}

.plan_pool_fourth_row > div > div {
  display: flex;
}

ul.tetsLi li {
  font-size: 16px !important;
  color: #f6f6f6;
  margin-bottom: 16px;
  list-style: auto;
}
